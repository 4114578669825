import React, { useState, useEffect } from 'react';
import api from "../../services/api";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { verifyLogin } from "./../../utils/verifyLogin";
import { formatCurrencyBRL } from "./../../utils/formatCurrencyBRL";

import { useParams, useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";

import { ContainerCart, Title } from "./style.js";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import ButtonDefault from "../../components/ButtonDefault";
import InputDefault from "../../components/InputDefault";

import { useCart } from './../../utils/CartContext';

const Cart = () => {

  const [prodListCart, setProdListCart] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [sizeList, setSizeList] = useState([]);

  const navigate = useNavigate();
  const { setCartLength } = useCart();

  useEffect(() => {
    if (!verifyLogin()) {
      navigate(`/`);
    }

    const prodList = JSON.parse(localStorage.getItem('productCart'));
    if (prodList) {
      setProdListCart(prodList)
    }

  }, []);

  useEffect(() => {
    api
      .get("get_all_sizes")
      .then((response) => {
        setSizeList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    api
      .get("get_all_colors")
      .then((response) => {
        setColorList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const findSizeById = (id) => {
    const size = sizeList.find((size) => size.ID_DM004 === parseInt(id));
    return size ? size.SG_TAMANHO : "";
  };

  const findColorById = (id) => {
    const color = colorList.find((color) => color.ID_DM003 === parseInt(id));
    return color ? color.NM_COR : "";
  };

  const calculateTotalPrice = (quantities, price) => {
    const totalQuantities = Object.values(quantities).reduce((acc, curr) => acc + curr, 0);
    return totalQuantities * price;
  };

  const grandTotal = prodListCart.reduce((acc, prod) => acc + calculateTotalPrice(prod.quantities, prod.price), 0);

  const removeItem = (productIndex, colorId, sizeId) => {
    const updatedProdList = [...prodListCart];
    const product = updatedProdList[productIndex];

    const key = `${colorId}-${sizeId}`;
    product.quantities[key]--;

    if (product.quantities[key] === 0) {
      delete product.quantities[key];
    }

    if (Object.keys(product.quantities).length === 0) {
      updatedProdList.splice(productIndex, 1);
    }
    
    setProdListCart(updatedProdList);
    localStorage.setItem('productCart', JSON.stringify(updatedProdList));
    
    console.log(updatedProdList)
    setCartLength(updatedProdList.length);
  };

  const addItem = (productIndex, colorId, sizeId) => {
    const updatedProdList = [...prodListCart];
    const product = updatedProdList[productIndex];

    const key = `${colorId}-${sizeId}`;
    if (!product.quantities[key]) {
      product.quantities[key] = 0;
    }
    product.quantities[key]++;

    setProdListCart(updatedProdList);
    localStorage.setItem('productCart', JSON.stringify(updatedProdList));
  };

  const updateItemQuantity = (productIndex, colorId, sizeId, newQuantity) => {
    const updatedProdList = [...prodListCart];
    const product = updatedProdList[productIndex];
  
    const key = `${colorId}-${sizeId}`;
  
    if (newQuantity < 1) {
      delete product.quantities[key];
      if (Object.keys(product.quantities).length === 0) {
        updatedProdList.splice(productIndex, 1);
      }
    } else {
      product.quantities[key] = newQuantity;
    }
  
    setProdListCart(updatedProdList);
    localStorage.setItem('productCart', JSON.stringify(updatedProdList));
    setCartLength(updatedProdList.length);
  };

  
  const calculateTotalQuantity = (quantities) => {
    const totalQuantities = Object.values(quantities).reduce((acc, curr) => acc + curr, 0);
    return totalQuantities;
  };

  return (
    <>
      <Header />
      <ContainerCart>
        <Container className="conShadown">
          <Title>Carrinho de Compras ({prodListCart.length})</Title>
          <br />
            {prodListCart.length > 0 ?
            <>
              <Row>
                <Col sm={12}>
                  <Row className='cabecalhoCart'>
                    <Col sm={12} md={3} className="cellCart">
                      <p>Nome do Produto</p>
                    </Col>
                    <Col sm={12} md={4} className="cellCart">
                      <p>Variações</p>
                    </Col>
                    <Col sm={12} md={1} className="cellCart" style={{ justifyContent: 'right' }}>
                      <p>Quantidade</p>
                    </Col>
                    <Col sm={12} md={2} className="cellCart" style={{ justifyContent: 'right' }}>
                      <p>Preço Unitário</p>
                    </Col>
                    <Col sm={12} md={2} className="cellCart" style={{ justifyContent: 'right' }}>
                      <p>Total</p>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12}>
                  {prodListCart.map((prod, index) => {
                    const totalPrice = calculateTotalPrice(prod.quantities, prod.price);
                    const totalQuantity = calculateTotalQuantity(prod.quantities);
                    return (
                      <Row className='prodCard' key={index}>
                        <Col sm={12} md={3} className="cellCart">
                          <p>{prod.name} - Lado: {prod.laterality == 'C' ? 'Canhoto' : prod.laterality == 'D' ? 'Destro' : 'Neutro'}</p>
                        </Col>
                        <Col sm={12} md={4}>
                          <Table striped bordered hover size="sm">
                            <thead>
                              <tr>
                                <th>Cor</th>
                                <th>Tamanho</th>
                                <th>Quantidade</th>
                                <th>Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.entries(prod.quantities)
                                .map(([key, value]) => {
                                  const [colorId, sizeId] = key.split('-');
                                  const color = findColorById(colorId);
                                  const size = findSizeById(sizeId);
                                  return { color, size, colorId, sizeId, value };
                                })
                                .sort((a, b) => a.color.localeCompare(b.color))
                                .map(({ color, size, colorId, sizeId, value }) => {
                                  return (
                                    <tr key={`${colorId}-${sizeId}`}>
                                      <td>{color}</td>
                                      <td style={{ textAlign: 'center' }}>{size}</td>
                                      <td style={{ textAlign: 'center' }}>
                                      <InputDefault
                                          type="number"
                                          value={value}
                                          style={{ width: '50px', textAlign: 'center' }}
                                          onChange={(e) =>
                                            updateItemQuantity(
                                              index,
                                              colorId,
                                              sizeId,
                                              parseInt(e.target.value)
                                            )
                                          }
                                          onBlur={(e) => {
                                            const newValue = parseInt(e.target.value);
                                            if (newValue <= 0) {
                                              updateItemQuantity(index, colorId, sizeId, -1);
                                            }
                                          }}
                                        />
                                      </td>
                                      <td style={{ textAlign: 'center' }}>
                                        <Button
                                          variant="danger"
                                          size="sm"
                                          onClick={() => removeItem(index, colorId, sizeId)}
                                        >
                                          -
                                        </Button>{' '}
                                        <Button
                                          variant="success"
                                          size="sm"
                                          onClick={() => addItem(index, colorId, sizeId)}
                                        >
                                          +
                                        </Button>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </Table>
                        </Col>
                        <Col xs={6} md={1} style={{ justifyContent: 'center', fontWeight: '600' }} className="cellCart">
                          <p>{totalQuantity}</p>
                        </Col>
                        <Col xs={6} md={2} style={{ justifyContent: 'right', fontWeight: '600' }} className="cellCart">
                          <p>{formatCurrencyBRL(prod.price)}</p>
                        </Col>
                        <Col xs={6} md={2} style={{ justifyContent: 'right', fontWeight: '600' }} className="cellCart">
                          <p>{formatCurrencyBRL(totalPrice)}</p>
                        </Col>
                        <hr />
                      </Row>
                    );
                  })}
                </Col>
              </Row>
              <Row className='footerCart'>
                <Col xs={12} md={8} style={{ justifyContent: 'left', fontWeight: '600' }} className="cellCart">
                  <Link to="/"><ButtonDefault Text={'Continuar Comprando'} Width={'300px'} Margin={'0px 20px 0px 0px'} /></Link>
                  <Link to="/checkout"><ButtonDefault Text={'Finalizar Pedido'} Width={'200px'} style={{ background: 'green' }} /></Link>
                </Col>
                <Col xs={12} md={4}>
                  <p className='totalsCart'>Valor Total: {formatCurrencyBRL(grandTotal)}</p>
                </Col>
              </Row>
            </>
            : ''
          }
        </Container>
      </ContainerCart>
      <Footer />
    </>
  );
}

export default Cart;

